import { ComplainInfoService } from "./ComplainInfoService";
import { ComplainInfo } from "./ComplainInfo";
import type { TApplicationState } from "~/components/global/application/types/TApplicationState";
import type { ApplicationState } from "~/components/global/application/models/ApplicationState";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
import type { AsyncData } from "#app";
import { $useFetch } from "~/composables/useFetch";

export class ComplainInfoApiService extends ComplainInfoService {
	constructor(complainData: ComplainInfo, complainState: ApplicationState) {
		super(complainData, complainState);
	}

	fetchComplainInfo(): AsyncData<TApplicationState | null, TFetchError> {
		return $useFetch<TApplicationState, TFetchError>(this.getComplainApiUrl, {
			method: "GET",
			key: `complain-${this.complainData.getApplicationGuid}`,
			default: () => ({
				uuid: "",
				type: "",
				attributes: {
					parent: "",
					reason: "",
					date: "",
					answer: "",
					answer_date: "",
				},
			}),
			transform: (mutations: { data: TApplicationState }) => mutations.data,
		});
	}
}
